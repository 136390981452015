export const resultsdisclosures = [
    {
        id: 0,
        Heading1: "FY 2023-24",
        Heading2: "Voting Results & Scrutinizer’s Report of 30th AGM ( For FY 2023-24) held on 25th March 2025",
        PDF: "https://educomp.com/Data/votingresults_23_24.pdf",
        isActive: 1
    },
    {
        id: 1,
        Heading1: "FY 2020-21",
        Heading2: "Voting Results & Scrutinizer’s Report of 23rd AGM ( For FY 2016-17) held on 29th June 2020",
        PDF: "https://educomp.com/Data/Voting-Results-and-Scrutinizer-Report.pdf",
        isActive: 1
    },
    {
        id: 2,
        Heading1: "FY 2020-21",
        Heading2: "Proceedings of 23rd AGM ( For FY 2016-17) held on 29th June 2020",
        PDF: "https://educomp.com/Data/23rdAGMProceedingsESLFY201617.pdf",
        isActive: 1
    },
    {
        id: 3,
        Heading1: "FY 2016-17",
        Heading2: "Voting Results & Scrutinizer’s Report of AGM 30th September, 2016",
        PDF: "https://educomp.com/Data/01102016_Voting_Result_Scrutinizer_Report-ESL.pdf",
        isActive: 1
    },
    {
        id: 4,
        Heading1: "FY 2015-16",
        Heading2: "Proceedings of AGM 28th September, 2015",
        PDF: "https://educomp.com/Data/proceeding-AGM-28-09-2015.pdf",
        isActive: 1
    },
    {
        id: 5,
        Heading1: "FY 2015-16",
        Heading2: "Scrutinizer Report 28th September, 2015",
        PDF: "https://educomp.com/Data/Scrutinizer-Report-AGM-28-09-2015.pdf",
        isActive: 1
    },
    {
        id: 6,
        Heading1: "FY 2014-15",
        Heading2: "Postal Ballot Results 11th March, 2015",
        PDF: "https://educomp.com/Data/Results-Postal-Ballot-11-03-2015.pdf",
        isActive: 1
    },
    {
        id: 7,
        Heading1: "FY 2014-15",
        Heading2: "Proceedings of AGM 29th September, 2014",
        PDF: "https://educomp.com/Data/proceeding-AGM-29-09-2014.pdf",
        isActive: 1
    },
    {
        id: 8,
        Heading1: "FY 2014-15",
        Heading2: "Scrutinizer Report 28th September, 2015",
        PDF: "https://educomp.com/Data/Scrutinizer-Report-AGM-29-09-2014.pdf",
        isActive: 1
    },
    {
        id: 9,
        Heading1: "FY 2014-15",
        Heading2: "Corrigendum for result of AGM 29th September, 2014",
        PDF: "https://educomp.com/Data/Corrigendum-for-AGM-result-29-09-2014.pdf",
        isActive: 1
    },
    {
        id: 10,
        Heading1: "FY 2014-15",
        Heading2: "Postal Ballot Results 11th August, 2014",
        PDF: "https://educomp.com/Data/Results-Postal-Ballot-11-08-2014.pdf",
        isActive: 1
    },
    {
        id: 11,
        Heading1: "FY 2013-14",
        Heading2: "Postal Ballot Results 26th March, 2014",
        PDF: "https://educomp.com/Data/Results-Postal-Ballot-26-03-2014.pdf",
        isActive: 1
    },
    {
        id: 12,
        Heading1: "FY 2013-14",
        Heading2: "Proceedings of AGM 26th September, 2013",
        PDF: "https://educomp.com/Data/proceeding-AGM-26-09-2013.pdf",
        isActive: 1
    },
    {
        id: 13,
        Heading1: "FY 2013-14",
        Heading2: "Clause 35A result of AGM 26th September, 2013",
        PDF: "https://educomp.com/Data/CLAUSE35A-Result-AGM-26-09-2013.pdf",
        isActive: 1
    },
    {
        id: 14,
        Heading1: "FY 2012-13",
        Heading2: "Proceedings of EGM 15th November, 2012",
        PDF: "https://educomp.com/Data/proceeding-EGM-15-11-2012.pdf",
        isActive: 1
    },
    {
        id: 15,
        Heading1: "FY 2012-13",
        Heading2: "Clause 35A result of EGM 15th November, 2012",
        PDF: "https://educomp.com/Data/CLAUSE35A-Result-EGM-15-11-2012.pdf",
        isActive: 1
    },
    {
        id: 16,
        Heading1: "FY 2012-13",
        Heading2: "Proceedings of AGM 26th September, 2012",
        PDF: "https://educomp.com/Data/proceeding-AGM-26-09-2012.pdf",
        isActive: 1
    },
    {
        id: 17,
        Heading1: "FY 2012-13",
        Heading2: "Clause 35A result of AGM 26th September, 2012",
        PDF: "https://educomp.com/Data/CLAUSE35A-Result-AGM-26-09-2012.pdf",
        isActive: 1
    },
    {
        id: 18,
        Heading1: "FY 2012-13",
        Heading2: "Proceedings of EGM 16th July, 2012",
        PDF: "https://educomp.com/Data/proceeding-EGM-16-07-2012.pdf",
        isActive: 1
    },
    {
        id: 19,
        Heading1: "FY 2012-13",
        Heading2: "Clause 35A result of EGM 16th July, 2012",
        PDF: "https://educomp.com/Data/CLAUSE35A-Result-EGM-16-07-2012.pdf",
        isActive: 1
    },
    {
        id: 20,
        Heading1: "FY 2011-12",
        Heading2: "Proceedings of AGM 24th September, 2011",
        PDF: "https://educomp.com/Data/Proceedings-AGM-24-09-2011.pdf",
        isActive: 1
    },
    {
        id: 21,
        Heading1: "FY 2011-12",
        Heading2: "Postal Ballot Results 26th July, 2011",
        PDF: "https://educomp.com/Data/Results-Postal-Ballot-26-07-2011.pdf",
        isActive: 1
    },
    {
        id: 22,
        Heading1: "FY 2011-12",
        Heading2: "Postal Ballot Results 14th April, 2011",
        PDF: "https://educomp.com/Data/Results-Postal-Ballot-14-04-2011.pdf",
        isActive: 1
    }
]